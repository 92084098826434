const useAttachmentViewer = () => {

    /**
     * Open PDF file
     * @param {string} blobUrl
     */
    const openPdf = (blobUrl) => {
        // Open a new window for other file types and embed the file in an iframe
        const newWindow = window.open();

        if (newWindow !== null) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title></title>
                    </head>
                    <body style="margin:0">
                        <iframe id="viewer" frameborder="0" style="border:none; width:100%; height:100vh;"></iframe>
                    </body>
                </html>
            `);
            newWindow.document.close();

            const iframe = newWindow.document.getElementById("viewer");
            iframe.setAttribute("src", blobUrl);

            // Revoke the Blob URL after the new window has loaded the content
            newWindow.onload = () => {
                URL.revokeObjectURL(blobUrl);
            };
        } else {
            // Fallback for mobile devices
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'file'; // Optional: specify a default filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the Blob URL after a short delay to release memory
            setTimeout(() => {
                URL.revokeObjectURL(blobUrl);
            }, 100);
        }
    }

    /**
     * Open image file
     * @param {string} blobUrl
     */
    const openImage = (blobUrl) => {
        // Open the Blob URL in a new window for images
        const newWindow = window.open(blobUrl, '_blank');

        if (newWindow) {
            newWindow.onload = () => {
                // Revoke the Blob URL after the new window has loaded the content
                URL.revokeObjectURL(blobUrl);
            };
        } else {
            // Fallback for mobile devices
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'file'; // Optional: specify a default filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the Blob URL after a short delay to release memory
            setTimeout(() => {
                URL.revokeObjectURL(blobUrl);
            }, 100);
        }
    }

    /**
     * Open attachment file
     * @param {string} data       Base64 data
     * @param {string} fileType   File mime type
     */
    const openAttachmentFile = (data, fileType) => {
        // Create a Blob from the data
        const byteCharacters = atob(data.data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: fileType});

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // First check if userAgent is Android, then open the file in a new window
        if (navigator.userAgent.match(/(Android)/)) {
            const newWindow = window.open(blobUrl);
            if (newWindow !== null) {
                newWindow.onload = () => {
                    URL.revokeObjectURL(blobUrl);
                };
                return
            }
        }

        // Check if the file type is not pdf
        if (fileType === 'application/pdf') {
            openPdf(blobUrl);
        } else {
            openImage(blobUrl);
        }
    }

    return { openAttachmentFile }
}

export default useAttachmentViewer;
