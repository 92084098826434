import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Card, Col, Row, Form } from "react-bootstrap";
import { messages as messageCenterMessages } from "../MessageCenter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { marked } from 'marked';

// services
import { getAttachmentData, createComment } from "../../../services/messagecenter";
import { validate, formatDate, formatTime  } from "../../../services/helpers";

// components
import TextInputWithAttachments from "../../../components/TextInputWithAttachments";

// hooks
import useAttachmentViewer from "../../../hooks/useAttachmentViewer";

// translations
export const messages = defineMessages({
    category: {
        id: "Kategoria",
        defaultMessage: 'Kategoria'
    },
    subject: {
        id: "Aihe",
        defaultMessage: 'Aihe'
    },
    commentSuccess: {
        id: "Kiitos viestistäsi!",
        defaultMessage: 'Kiitos viestistäsi!'
    },
    commentFailed: {
        id: "Viestin lähettämisessä tapahtui virhe. Yritä uudelleen.",
        defaultMessage: 'Viestin lähettämisessä tapahtui virhe. Yritä uudelleen.'
    },
    supportAuthorPrefix: {
        id: "Ropo - Asiakaspalvelija",
        defaultMessage: 'Ropo - Asiakaspalvelija'
    },
    commentNotAvailable: {
        id: "Tämä viestiketju on ratkaistu ja suljettu.",
        defaultMessage: 'Tämä viestiketju on ratkaistu ja suljettu.'
    },
    messagePlaceholder: {
        id: "Kirjoita viesti",
        defaultMessage: 'Kirjoita viesti'
    },
    openFile: {
        id: "Avaa tiedosto",
        defaultMessage: 'Avaa tiedosto'
    }
});

const MessageContent = (props) => {
    const lng = props.intl.formatMessage;
    const [conversationHistory, setConversationHistory] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [existingAttachments, setExistingAttachments] = useState(props.attachments || []);
    const [newAttachments, setNewAttachments] = useState([]);
    const [comments, setComments] = useState(props.comments || []);
    const scrollMessagesRef = createRef();
    const [commentSubmitted, setCommentSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { openAttachmentFile } = useAttachmentViewer();

    // Attachment configurations
    const acceptedFileTypes = '.jpg,.jpeg,.png,.svg,.tif,.tiff,.gif,.bmp,.docx,.doc,.odt,.xlsx,.xls,.ods,.pptx,.ppt,.odp,.pdf,.txt,.csv';
    const acceptedFileSize = 30;
    const byteConversionFactor = 1024 * 1024;
    const sizeUnit = 'MB';
    const acceptedFileAmount = 10;


    useEffect(() => {
        if (existingAttachments.length > 0) {
            // if ticket has attachments, add them to comments and sort by date
            const combinedArray = checkAttachments(existingAttachments, comments);
            setConversationHistory(combinedArray);
        } else if (comments.length > 0) {
            // ticket has no attachments, only use comments
            setConversationHistory(comments);
        } else {
            // ticket has no comments or attachments
            setConversationHistory([]);
        }
    }, [props.id, comments, existingAttachments]);

    useEffect(() => {
        // Update attachment and comment states when props.id changes
        setExistingAttachments(props.attachments || []);
        setComments(props.comments || []);
    }, [props.id, props.attachments, props.comments]);
    
    useEffect(() => {
        // scroll to the bottom of the message
        if (scrollMessagesRef.current) {
            scrollMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [conversationHistory]);
    
    /**
     * Open the file in a new tab.
     * @param data
     * @param fileType
     */
    const openFileInNewTab = (data, fileType) => {
        try {
            openAttachmentFile(data, fileType);
        } catch (error) {
            props.showAlerts({
                message: lng(messageCenterMessages.errorOccurredTryAgain),
                type: 'danger'
            });
        }
    }
    
    /**
     * Check attachments, combine them to comments and sort by date to show them in the correct order.
     *
     * @param {*} attachments
     * @param {*} comments
     * @returns Array - combined array of comments and attachments
     */
    function checkAttachments(attachments, comments = []) {
        let allAttachments = [];
        
        // Extract all attachment IDs from comments
        const commentAttachmentIds = comments.reduce((ids, comment) => {
            if (comment.attachment_ids && comment.comment.length > 0) {
                ids.push(...comment.attachment_ids);
            }
            return ids;
        }, []);

        attachments.forEach((attachment) => {
            // Check if the attachment ID is found in the comments
            if (!commentAttachmentIds.includes(attachment.id)) {
                // Add fields for easier handling on the UI
                attachment.sent_date = attachment.created;
                attachment.type = 'attachment';
                attachment.attachment_ids = [attachment.id];
                attachment.is_support = props.isSupport;

                allAttachments.push(attachment);
            }
        });

        // combine comments and attachments for sorting
        let combinedArray = comments.concat(allAttachments);

        // sort attachments by date
        combinedArray.sort((a, b) => {
            return new Date(a.sent_date) - new Date(b.sent_date);
        });

        // return combined array of comments and attachments
        return combinedArray;
    }
    
    /**
     * Process attachment ids and return buttons for each attachment.
     * @param attachmentIds
     * @param messageId
     * @returns {*}
     */
    function processAttachmentIds(attachmentIds, messageId) {
        
        // Define a mapping of file extensions to FontAwesome icons
        const fileTypeIcons = {
            'jpg': fas.faFileImage,
            'jpeg': fas.faFileImage,
            'png': fas.faFileImage,
            'svg': fas.faFileImage,
            'tif': fas.faFileImage,
            'tiff': fas.faFileImage,
            'gif': fas.faFileImage,
            'bmp': fas.faFileImage,
            'docx': fas.faFileWord,
            'doc': fas.faFileWord,
            'odt': fas.faFileWord,
            'xlsx': fas.faFileExcel,
            'xls': fas.faFileExcel,
            'ods': fas.faFileExcel,
            'pptx': fas.faFilePowerpoint,
            'ppt': fas.faFilePowerpoint,
            'odp': fas.faFilePowerpoint,
            'pdf': fas.faFilePdf,
            'txt': fas.faFileAlt,
            'csv': fas.faFileCsv
        };
        
        // Set to keep track of filenames that have already been processed
    const processedFilenames = new Set();
    
        // filter out attachments
        const matchedAttachments = attachmentIds.map(attachmentId => {
            const attachment = existingAttachments.find(att => att.id === attachmentId);
            return attachment ? { id: attachmentId, filename: attachment.filename, mimetype: attachment.mimetype } : null;
        }).filter(att => att !== null);
        
        return matchedAttachments.map((attachment) => {
            if (attachment.filename && !processedFilenames.has(attachment.filename)) {
                // Add filename to the set of processed filenames
                processedFilenames.add(attachment.filename);
    
                // Extract file extension
                const fileExtension = attachment.filename.split('.').pop().toLowerCase();
    
                // Determine the FontAwesome icon
                const icon = fileTypeIcons.hasOwnProperty(fileExtension) ? fileTypeIcons[fileExtension] : fas.faFile;
    
                return (
                    <button key={attachment.id} type="button" className={'actionlist-button btn btn-tertiary-sidemenu'} onClick={() => getAttachment(attachment.id, messageId, attachment.mimetype)} aria-label={lng(messages.openFile)}>
                        <FontAwesomeIcon icon={icon} className='me-2 attachment-icon' />
                        {attachment.filename}
                    </button>
                );
            }
        }).filter(att => att !== null);
    }
    
    /**
     * Get attachment data and open it in a new tab.
     * @param attachmentId
     * @param messageId
     * @param mimetype
     */
    function getAttachment(attachmentId, messageId, mimetype) {
        getAttachmentData(attachmentId, messageId)
            .then(response => {
                openFileInNewTab(response, mimetype);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    // authentication failed, redirect back to authentication
                    const alert = {message: lng(messageCenterMessages.authError), type: 'danger'};
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    // Pass the alert to MessageCenter.js
                    props.showAlerts({
                        message: lng(messageCenterMessages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            });
    }

    /**
     * Handle changes in input and remove errors.
     *
     * @param {*} e
     */
    function handleInputs(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        } 

        setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    }

    /**
     * Handle attachments and set them to state.
     * @param attachments
     */
    function handleAttachments(attachments) {
        setNewAttachments(attachments);
        props.setAttachments(attachments);
    }

    /**
     * Validate and map data for submit purposes.
     * 
     * @param {*} data 
     * @param {*} attachments
     */
    function validateAndMapDataForSubmit(data, attachments) {
        let requestData = {
            comment: data['message-input']
        };

        let objectIds = [];
        
        // Add object_ids for attachments
        for (const attachment of attachments) {
            if (typeof attachment.objectid !== 'undefined' && attachment.objectid !== '') {
                objectIds.push(attachment.objectid);
            }
        }
        requestData.object_ids = objectIds;

        // return request data that is ready for submit
        return requestData;
    }

    /**
     * Handle form submission to create a new comment.
     */
    async function handleSubmit(e) {
        e.preventDefault();
        
        if (validate(e.currentTarget)) {
            setIsSubmitting(true);
            const formData = validateAndMapDataForSubmit(inputValues, newAttachments);
            try {
                await createComment(props.id, formData);
                    
                // Handle successful response
                props.showAlerts({
                    message: lng(messages.commentSuccess),
                    type: 'success'
                });

                setCommentSubmitted(true);
                setInputValues({});
                setNewAttachments([]);
                props.setAttachments([]);

                // Set isUpdated to true to trigger a reload of the message center
                props.setForceUpdateOfMessages(true);
                setIsSubmitting(false);
                
            } catch (error) {
                // Handle error
                props.showAlerts({
                    message: lng(messages.commentFailed),
                    type: 'danger'
                });
            }
        }
    }

    // Function to update comment submit status value
    const updateCommentSubmitValue = (value) => {
        setCommentSubmitted(value);
    };

    const commentElement = (comment, index) => {
        if (comment.type !== 'attachment' && comment.comment.length === 0) {
            return null
        }

        const sameDate = index > 0 &&
            formatDate(comment.sent_date) === formatDate(conversationHistory[index - 1].sent_date);

        let authorName = comment.author_name;

        if (authorName === undefined || authorName === null) {
            authorName = '';
        } else {
            authorName = authorName.split(' ')[0];
        }

        return (
            <div key={'comment-' + comment.id}>
                {!sameDate && (
                    <p className={'text-muted fs-6 mt-4 mb-2' + (comment.author === 'support' ? ' text-end' : ' text-center')}>
                        {formatDate(comment.sent_date)}
                    </p>
                )}

                {comment.is_support && (
                    <p className="author-name">{`${lng(messages.supportAuthorPrefix)} ${authorName}`}</p>
                )}

                <Row key={'comment-' + comment.id} className={'mb-2 message ' + (comment.is_support === true ? ' support' : 'customer')} >
                    <Col xs={12}>
                        {comment.attachment_ids ? processAttachmentIds(comment.attachment_ids, props.id) : ''}
                        {comment.comment && comment.comment.length > 0 && (
                            <p className="mb-2" dangerouslySetInnerHTML={{ __html: marked(comment.comment) }}></p>
                        )}
                        <p className='text-muted fs-6 mb-1'>{formatTime(comment.sent_date)}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className='scrollable-content-message'>
            <Form className="message-center-form" id={props.formId} onSubmit={handleSubmit} noValidate>
            <Card key={'messagecontent-' + props.id} className='p-2 ps-sm-4 pe-sm-4 pb-0 messagecontent'>
                <Card.Body>
                    <Row className={'message ' + (props.isSupport === true ? ' support' : 'customer')}>
                        <Col xs={12}>
                            <p className='mb-1'>{lng(messages.category)}: {props.reason ?? ''}</p>
                            <p className='mb-1'>{lng(messages.subject)}: {props.summary ?? ''}</p>
                            {props.description && props.description.length > 0 && (
                                <p dangerouslySetInnerHTML={{ __html: marked(props.description ?? '') }}></p>
                            )}
                            <p className='text-end text-muted fs-6 mb-0'>{props.date !== '' ? formatDate(props.date) : ''} {props.date !== '' ? formatTime(props.date) : ''}</p>
                        </Col>
                    </Row>

                    {/* List all message comments below the original message */}
                    {conversationHistory.map((comment, index) => commentElement(comment, index))}
                    <div className="scroll-message-ref" ref={scrollMessagesRef}></div>
                </Card.Body>

                {props.status != 'Closed' ? (
                    <div className='fixed-element-bottom'>
                        <TextInputWithAttachments
                            intl={props.intl}
                            fieldId="message-input"
                            fieldName={lng(messages.messagePlaceholder)}
                            required={true}
                            attachmentsEnabled={true}
                            acceptedFileAmount={acceptedFileAmount}
                            acceptedFileTypes={acceptedFileTypes}
                            acceptedFileSize={acceptedFileSize}
                            byteConversionFactor={byteConversionFactor}
                            sizeUnit={sizeUnit}
                            handleInputChange={handleInputs}
                            handleAttachments={handleAttachments}
                            history={props.history}
                            commentSubmitted={commentSubmitted}
                            updateCommentSubmitValue={updateCommentSubmitValue}
                            formId={props.formId}
                            messageContent={true}
                            files={props.files}
                            isSubmitting={isSubmitting}
                        />
                    </div>
                 ) : ( 
                    <div className='commenting-not-available'>{lng(messages.commentNotAvailable)}</div>
                 )}
            </Card>
            </Form>
        </div>
    );
};


MessageContent.propTypes = {
    'intl': PropTypes.object,
    'id': PropTypes.string,
    'isSupport': PropTypes.bool,
    'status': PropTypes.string,
    'date': PropTypes.string,
    'summary': PropTypes.string,
    'description': PropTypes.string,
    'comments': PropTypes.array,
    'attachments': PropTypes.array,
    'history': PropTypes.object,
    'showAlerts': PropTypes.func,
    'reason': PropTypes.string,
    'formId': PropTypes.string,
    'files': PropTypes.array,
    'setAttachments': PropTypes.func,
    'setForceUpdateOfMessages': PropTypes.func
};

export default injectIntl(MessageContent);