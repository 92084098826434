import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from "prop-types";
import { Card, Col } from "react-bootstrap";

const Notification = (props) => {
    
    const [type, setType] = useState('primary');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [label, setLabel] = useState('');
    
    // Set the property values if given
    useEffect(() => {
        if (props.type !== undefined) {
            setType(props.type);
        }
        if (props.title !== undefined) {
            setTitle(props.title);
        }
        if (props.text !== undefined) {
            setText(props.text);
        }
        if (props.label !== undefined) {
            setLabel(props.label);
        }
    }, [props.type, props.title, props.text]);
    
    return (
        <Col lg={6} className="d-flex align-items-stretch">
            <Card className={ "notification flex-grow-1 " + type } onClick={() => props.onClickFunction()} aria-label={label}>
                <Card.Body>
                    <Card.Title as='h3'>{ title }</Card.Title>
                    <Card.Text>{ text }</Card.Text>
                </Card.Body>
                <div className="notification-icon">
                    <FontAwesomeIcon icon={ far.faChevronRight } aria-hidden="true"/>
                </div>
            </Card>
        </Col>
    );
};

Notification.propTypes = {
    'type': PropTypes.string,
    'title': PropTypes.string,
    'text': PropTypes.string,
    'onClickFunction': PropTypes.func,
    'label': PropTypes.string
};

export default Notification;