import PropTypes from "prop-types";
import { Card, Col, Placeholder, Row } from "react-bootstrap";

const InvoiceCardSkeleton = (props) => {
    
    // Set prefix for list item keys
    const keyPrefix = new Date().getTime() + '_';
    
    return (
        <>
            {Array(props.count).fill(null).map((value, index) => (
                <Card className="invoice skeleton-card" style={{opacity: 1/(index+1)}} key={keyPrefix + index}>
                    <Card.Body>
                        <Row>
                            <Col xs={6} lg={3} className="mb-2 mb-md-0">
                                <Placeholder as="p" animation="glow" className="fs-6 mb-0">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="h5" animation="glow">
                                    <Placeholder xs={10} />
                                </Placeholder>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Placeholder as="p" animation="glow" className="fs-6 mb-0">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="h5" animation="glow">
                                    <Placeholder xs={8} />
                                </Placeholder>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Placeholder as="p" animation="glow" className="fs-6 mb-0">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="h5" animation="glow">
                                    <Placeholder xs={5} />
                                </Placeholder>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Placeholder as="p" animation="glow" className="fs-6 mb-0">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="h5" animation="glow">
                                    <Placeholder xs={5} />
                                </Placeholder>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                )
            )}
        </>
    );
};

InvoiceCardSkeleton.propTypes = {
    'count': PropTypes.number.isRequired,
};
export default InvoiceCardSkeleton;