import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Badge, Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { formatDate, formatMoney } from '../../../../services/helpers';
import { userGetLocale } from '../../../../services/user';
import Moment from 'moment';

import { messages as jobmessages, prohibited } from '../../messages';
import PaymentDetails from './PaymentDetails';
import StatusBadge from "../../../../components/StatusBadge";
import PaydateText from "../../../../components/PaydateText/PaydateText";

const InvoiceShortOverview = (props) => {

    const lng = props.intl.formatMessage;
    const [payplanSteps, setPayplanSteps] = useState(null);
    const [nextPayplanStep, setNextPayplanStep] = useState(null);
    const [accordionOpenKey, setAccordionOpenKey] = useState('0');
    const userLocale = userGetLocale();

    useEffect(() => {

        if (props.job.payplan !== null) {

            let allPaid = true;
            setPayplanSteps(props.job.payplan);

            // also catch nearest unpaid payplan step
            for (const step of props.job.payplan) {
                if (!step.ispaid) {
                    allPaid = false;
                    setNextPayplanStep(step);
                    break;
                }
            }

            // if all are paid, open accordion
            if (allPaid) {
                setAccordionOpenKey('1');
            }
        } else {
            setPayplanSteps(null);
        }

    }, [props.job]);

    let newPaydateButtonEnabled = props.job.modpaydate !== null && props.job.modpaydate;

    // short summary of the job (paydate / payplan steps + invoice related fees)
    return (
        <Card className={'fullwidth-mobile invoice-details job-shortsummary no-rounding-top p-4 p-md-0' + (props.job.makepayment !== null && props.job.makepayment ? '': ' rounding-top-mobile')}>
            <Card.Body>
                <Row>
                    <ul className='list-unstyled'>
                        {props.job.makepayment !== null && !props.job.makepayment && (
                            <span className='d-md-none float-end text-end'>
                                <StatusBadge statusCode={props.job.job_main_statuscode} dueDate={props.job.paydate} lng={lng} />
                            </span>
                        )}
                        
                        {/* show paydate, if job does not have a payment plan */}
                        {payplanSteps === null ? (
                            <li id='invoice-overview-paydate'>
                                <p className="pb-3 pb-lg-0">
                                    <span className='label'>{lng(jobmessages.paydate)}</span>
                                    {/* Display due date text */}
                                    <PaydateText
                                        intl={props.intl}
                                        statuscode={props.job.statuscode ?? null}
                                        paydate={props.job.paydate ?? null}
                                    />
                                    {/* move paydate button should not be visible to paid jobs */}
                                    {props.job.makepayment !== null && props.job.makepayment && (
                                        <span className='float-end text-end accordion-inline-button'>
                                            <OverlayTrigger
                                                key='new-paydate-button'
                                                placement='top'
                                                trigger={['hover', 'click']}
                                                overlay={
                                                    !newPaydateButtonEnabled ? (
                                                        <Tooltip id={'paydate-cant-be-changed'}>
                                                            {lng(prohibited.paymentTermExtensionCannotBeMade)}
                                                        </Tooltip>
                                                    ) : (<></>)
                                                    }>
                                                <Button id='new-paydate-button' aria-label={lng(jobmessages.movePaydate)} className={'extend-duedate d-lg-inline mt-md-2 ' + (!newPaydateButtonEnabled ? 'secondary-disabled' : '')} variant='secondary' size='sm' onClick={() => props.handlePaydateModal()}>
                                                    {lng(jobmessages.movePaydate)} &nbsp; <FontAwesomeIcon icon={far.faClockRotateLeft} />
                                                </Button>
                                            </OverlayTrigger>

                                            {props.job.dopayplan !== null && props.job.dopayplan ? (
                                                <Button className='show-payplan ms-2 mt-2 extend-duedate d-block d-lg-inline' aria-label={lng(jobmessages.payplan)} variant='secondary' size='sm' onClick={() => props.handlePayplanModal()}>
                                                    {lng(jobmessages.payplan)} &nbsp; <FontAwesomeIcon icon={far.faCalendarClock} />
                                                </Button>
                                            ) : (
                                                <OverlayTrigger
                                                    key='show-payplan'
                                                    trigger={['hover', 'click']}
                                                    overlay={!props.job.dopayplan ? (
                                                        <Tooltip id='payplan-cannot-be-created'>
                                                            {lng(prohibited.paymentplanCannotBeCreated)}
                                                        </Tooltip>
                                                    ) : (<></>)
                                                    }>
                                                    <Button className={'show-payplan ms-2 mt-2 extend-duedate d-block d-lg-inline ' + (!props.job.dopayplan ? 'secondary-disabled' : '')} variant='secondary' size='sm' onClick={() => props.handlePayplanModal()}>
                                                        {lng(jobmessages.payplan)} &nbsp; <FontAwesomeIcon icon={far.faCalendarClock} />
                                                    </Button>
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                    )}
                                </p>
                            </li>
                        ) : (
                            <li id='invoice-overview-payplan'>
                                <Accordion flush defaultActiveKey={accordionOpenKey}>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <Col md={6} xs={10}>
                                                <span className='label'>
                                                    {lng(jobmessages.payplan)}
                                                </span>
                                                {nextPayplanStep !== null && (
                                                    <p>{ lng(jobmessages.paymentIsDue1) } {formatMoney(nextPayplanStep.amount, userLocale, props.job.currency)} { lng(jobmessages.paymentIsDue2) } 
                                                    {Moment(nextPayplanStep.paydate).format("YYYY-MM-DD") <= Moment().format("YYYY-MM-DD") ? 
                                                        (<span className='text-danger'> {formatDate(nextPayplanStep.paydate)} <FontAwesomeIcon icon={far.faCircleExclamation} /></span>) :
                                                        (<span> {formatDate(nextPayplanStep.paydate)}</span>)
                                                    }
                                                    </p>
                                                )}
                                            </Col>
                                            <Col sm={{ span: 4, offset: 1 }} className='d-none d-sm-none d-md-block'>
                                                <span className='label float-end text-end accordion-icon-text' aria-label={lng(jobmessages.showPaymentPlan)}> { lng(jobmessages.showPaymentPlan) } </span>
                                            </Col>
                                        </Accordion.Header>
                                        <Accordion.Body id='payplan-steps'>
                                                {payplanSteps.map((step, index) => {
                                                    // is it due today
                                                    const isDueNow = Moment(step.paydate).format("YYYY-MM-DD") === Moment().format("YYYY-MM-DD");

                                                    // is it already due
                                                    const isDue = Moment(step.paydate).format("x") < Moment().format("x");

                                                    // is it paid
                                                    const isPaid = step.diff <= 0;

                                                    let rowClasses = 'step ';
                                                    let rowStatusText = false;

                                                    if (isPaid) {
                                                        rowClasses += 'paid';
                                                        rowStatusText = <span id='paid-label'>{lng(jobmessages.paid)}</span>;
                                                    }
                                                    else if (isDueNow) {
                                                        rowClasses += 'is-due';
                                                        rowStatusText = <span id='due-label-due-now'>{lng(jobmessages.dueNow)}</span>;
                                                    }
                                                    else if (isDue) {
                                                        rowClasses += 'is-due';
                                                        rowStatusText = <span id='due-label-due'>{lng(jobmessages.due)}</span>;
                                                    }

                                                    return (
                                                        <OverlayTrigger
                                                            key={'step-' + index}
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={rowStatusText ?
                                                                (<Tooltip>{rowStatusText}</Tooltip>) :
                                                                (<></>)}>
                                                            <Card className={'payplan-step ' + rowClasses}>
                                                                <Row>
                                                                    <Col xs={4} lg={4} className='text-end'>
                                                                        <p className='due-date'>
                                                                            <span className='label text-muted'>{lng(jobmessages.paydate)}</span>
                                                                            <span className='date'>{formatDate(step.paydate)}</span>
                                                                            <span className='payplan-status d-none d-xl-block'>{rowStatusText}</span>
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs={4} lg={4} className='text-end'>
                                                                        <p className='amount'>
                                                                            <span className='label text-muted'>{lng(jobmessages.sum)}</span>
                                                                            {formatMoney(step.amount, userLocale, props.job.currency)}
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs={4} lg={4} className='text-end'>

                                                                            {/* If paid, do not show openAmount */}
                                                                            {isPaid ? ('') : (
                                                                                <p className='open-amount'>
                                                                                    <span className='label text-muted'>{lng(jobmessages.openAmount)}</span>
                                                                                    <strong>{formatMoney(step.diff, userLocale, props.job.currency)}</strong>
                                                                                    {step.paid > 0 && 
                                                                                        <div><Badge pill bg="success">{lng(jobmessages.paid)} {formatMoney(step.paid, userLocale, props.job.currency)}</Badge></div>
                                                                                    }
                                                                                </p>
                                                                            )}

                                                                            {/* If paid value < 0 and not fully paid, don't show paid amount */}
                                                                            {step.paid <= 0 || !isPaid ? ('') : (
                                                                                <p className='paid'>
                                                                                    <span className='label text-muted'>{lng(jobmessages.paid)}</span>
                                                                                    <strong>{formatMoney(step.paid, userLocale, props.job.currency)}</strong>
                                                                                </p>
                                                                            )}

                                                                    </Col>
                                                                    <Badge className={rowClasses} pill>{index + 1}</Badge>
                                                                </Row>
                                                            </Card>
                                                        </OverlayTrigger>
                                                    );
                                                })
                                                }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </li>
                        )}

                        {/* Show all fees related to the invoice in desktop */}
                        <li className='d-none d-sm-none d-md-block'>
                            <Accordion flush defaultActiveKey={'0'}>
                                <Accordion.Item>
                                    <Accordion.Header>
                                        <Col md={8} sm={12}>
                                            <span className='label'>
                                                {lng(jobmessages.openTotal)}
                                            </span>
                                            {formatMoney(props.job.openamount, userLocale, props.job.currency)}
                                        </Col>
                                        <Col md={3} className='d-none d-sm-none d-md-block'>
                                            <span className='label float-end text-end accordion-icon-text' aria-label={lng(jobmessages.showDetails)}> { lng(jobmessages.showDetails) } </span>
                                        </Col>
                                    </Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <PaymentDetails
                                            job={props.job}
                                            {...props}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </li>
                        {/* Show only open total in mobile, rest of the fee information in PaymentDetails*/}
                        <li className='d-md-none' id='invoice-overview-opentotal-mobile'>
                            <Col >
                                <span className='label'>
                                    {lng(jobmessages.openTotal)} <span className='float-end text-end'> {formatMoney(props.job.openamount, userLocale, props.job.currency)}</span>
                                </span>
                            </Col>
                        </li>
                    </ul>
                </Row>
            </Card.Body>
        </Card>
    );
};

InvoiceShortOverview.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
    'handlePaydateModal': PropTypes.func,
    'handlePayplanModal': PropTypes.func  
};

export default InvoiceShortOverview;