import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessages } from "react-intl";
import Moment from "moment";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import PropTypes from 'prop-types';

import { getAllowedLangs, parseVariableString } from "../../../../services/helpers";
import enGB from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi'
import sv from 'date-fns/locale/sv';
// date-fns supports Norwegian Bokmål for datepicker
import nb from 'date-fns/locale/nb';
import { far } from "@fortawesome/pro-regular-svg-icons";
import AlertDialog from "../../../../components/AlertDialog";

registerLocale('fi', fi);
registerLocale('en', enGB);
registerLocale('se', sv);
registerLocale('no', nb);

// strings for translations
const messages = defineMessages({
    movePaydate: {
        id: "Siirrä eräpäivää",
        defaultMessage: "Siirrä eräpäivää"
    },
    vrk: {
        id: "vrk",
        defaultMessage: "vrk"
    },
    paragraph2: {
        id: "Eräpäivän siirrosta perittävä kulu",
        defaultMessage: "Eräpäivän siirrosta perittävä kulu"
    },
    paragraph3: {
        id: "Eräpäivän siirrosta aiheutuva kulu veloitetaan seuraavalla laskulla laskuttajan voimassa olevan hinnaston mukaisesti",
        defaultMessage: "Eräpäivän siirrosta aiheutuva kulu veloitetaan seuraavalla laskulla laskuttajan voimassa olevan hinnaston mukaisesti"
    },
    actionSave: {
        id: "Tallenna",
        defaultMessage: "Tallenna"
    },
    newPaydate: {
        id: "Uusi eräpäivä",
        defaultMessage: "Uusi eräpäivä"
    },
    modPayDateInfoText: {
        id: "Jos olet saanut laskun e-laskuna tai sinulla on suoramaksusopimus, huomioithan että MyRopossa tehty eräpäivänsiirtotieto ei välity pankkiisi, vaan muutos tulee tehdä sinne erikseen.",
        defaultMessage: "Jos olet saanut laskun e-laskuna tai sinulla on suoramaksusopimus, huomioithan että MyRopossa tehty eräpäivänsiirtotieto ei välity pankkiisi, vaan muutos tulee tehdä sinne erikseen."
    },
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    }
});

export { messages };

const NewPaydateModal = (props) => {

    let maxPaydays = 14;

    // check if modpaydatemaxdays is in reponse (won't exist before version 2025-03 changes, obsolete check after that)
    if (typeof props.modpaydatemaxdays !== 'undefined') {

        // set given maximum length for due date if it exceeds 14 days, or the job is in status 150
        if (props.modpaydatemaxdays > 14 || props.jobstatus == 150 ) {
            maxPaydays = props.modpaydatemaxdays;
        }

    } else if (typeof props.modpaydatemaxdays === 'undefined') {
        // if max.length exceeds 14 days, convert modpaydatemax to days
        if (props.modpaydatemax !== null && props.modpaydatemax !== '' && props.modpaydatemax > 2) {
            maxPaydays = props.modpaydatemax * 7;
        }

    }

    let start = Moment(props.paydate).add(14, 'days').toDate(); // first possible day for due date change

    if (typeof props.modpaydatemaxdays !== 'undefined' && props.jobstatus === 150) {
        // set flexible range starting from due date, not fixed amount of days (only after version 2025-03 changes, when modpaydatemaxdays is available)
        start = Moment(props.paydate).toDate();
    }

    const lng = props.intl.formatMessage;
    // get allowed languages from helpers
    const allowedLangs = getAllowedLangs();
    const locale = localStorage.getItem('locale');
    setDefaultLocale(locale !== null && allowedLangs.includes(locale) ? locale : 'fi');

    const timeRangeInfo = (typeof props.modpaydatemaxdays !== 'undefined' && props.modpaydatemaxdays > 14) || (typeof props.modpaydatemaxdays === 'undefined' && props.modpaydatemax > 2);
    const startdate = start;
    const enddate = Moment(props.paydate).add(maxPaydays, 'days').toDate(); // last possible day for due date change
    const [paydate, setPaydate] = useState(startdate);
    const statuscode = props.statuscode;
    const [paydateFeeText, setPaydateFeeText] = useState('');
    
    useEffect(() => {
        const customfeetext = parseVariableString(localStorage.getItem('locale'), props.modpaydatefeetext);

        if (customfeetext !== '') {
            // Display custom postponing fee text, if given
            setPaydateFeeText(customfeetext);
        } else if (props.modpaydatefee !== '' && props.modpaydatefeeblocked !== 1) {
            // Display postponing fee text with the sum, if fee is not blocked
            setPaydateFeeText(lng(messages.paragraph2) + ': ' + props.modpaydatefee);
        } else if (props.modpaydatefeeblocked === 1) {
            // Display postponing fee with generic text, if fee is blocked
            setPaydateFeeText(lng(messages.paragraph3));
        }
    }, []);
    
    // changed paydate is passed to the parent and also set here for the datepicker
    function handleDates(event) {
        props.handleDatepickerChange(event);
        setPaydate(event);
        props.showAlertDialog.show = false;
    }
    
    return (
        <Modal id="paydate-modal" show={ props.show } dialogClassName="new-paydate-form" onHide={ props.handlePaydateModal } aria-labelledby="modal-title-movepaydate">
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={ props.handlePaydateModal }>
                    <FontAwesomeIcon icon={ far.faArrowLeft } /> { lng(messages.backToInvoice) }
                </Button>
                <Modal.Title as="h4" id="modal-title-movepaydate">
                    { lng(messages.movePaydate) }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { timeRangeInfo && (
                    <p>
                        <FontAwesomeIcon icon={ ['fas', 'info-circle'] } />
                        <span> { lng(messages.movePaydate) } 14 - { maxPaydays } { lng(messages.vrk) }</span>
                    </p>
                )}
                { (() => {
                    if (statuscode === 0) {
                        return <p>{ lng(messages.modPayDateInfoText) }</p>;
                    }
                })() }
    
                {/* Display postponing fee text, if given */}
                { (paydateFeeText !== '') ? (
                    <p className="fw-bold text-danger">{ paydateFeeText }</p>
                ) : ('') }
    
                { props.showAlertDialog.show && (
                    <AlertDialog message={ props.showAlertDialog.message } type={ props.showAlertDialog.type } />
                ) }
                <Form id='newpaydate-form' onSubmit={ props.handleSubmit }>
                    <Form.Group controlId="newpaydate" className="form-group has-label text-center mb-3">
                        <DatePicker id="newpaydate" minDate={ startdate } maxDate={ enddate } selected={ paydate } onChange={ (e) => handleDates(e) } locale={ locale } className="form-control" inline />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        { lng(messages.actionSave) }
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};

NewPaydateModal.propTypes = {
    intl: PropTypes.object.isRequired,
    show: PropTypes.bool,
    modpaydatemax: PropTypes.number,
    paydate: PropTypes.string,
    statuscode: PropTypes.number,
    handleDatepickerChange: PropTypes.func,
    showAlertDialog: PropTypes.object,
    handlePaydateModal: PropTypes.func,
    modpaydatefee: PropTypes.any,
    modpaydatefeeblocked: PropTypes.any,
    modpaydatefeetext: PropTypes.any,
    handleSubmit: PropTypes.func,
    jobstatus: PropTypes.number,
    modpaydatemaxdays: PropTypes.number
};

export default NewPaydateModal;