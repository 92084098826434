import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { defineMessages } from "react-intl";
import AlertDialog from "../AlertDialog";
import axios from "axios";
import { userLogout, userRequestHeaders } from "../../services/user";
import { fas } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
    attachments: {
        id: "Liitteet",
        defaultMessage: "Liitteet"
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    duplicateFilesError: {
        id: 'Seuraavat tiedostot on jo liitetty viestiin',
        defaultMessage: 'Seuraavat tiedostot on jo liitetty viestiin'
    },
    tooLargeFilesError1: {
        id: 'Tiedoston maksimikoko on',
        defaultMessage: 'Tiedoston maksimikoko on'
    },
    tooLargeFilesError2: {
        id: 'Seuraavat tiedostot ylittivät maksimikoon',
        defaultMessage: 'Seuraavat tiedostot ylittivät maksimikoon'
    },
    tooManyFilesError: {
        id: 'Liian monta liitettä. Maksimimäärä on',
        defaultMessage: 'Liian monta liitettä. Maksimimäärä on'
    },
    uploadError: {
        id: 'Seuraavien tiedostojen lataaminen epäonnistui',
        defaultMessage: 'Seuraavien tiedostojen lataaminen epäonnistui'
    },
    uploadFile: {
        id: 'Lataa tiedosto',
        defaultMessage: 'Lataa tiedosto'
    },
    delete: {
        id: 'Poista',
        defaultMessage: 'Poista'
    },
    sendMessage: {
        id: 'Lähetä viesti',
        defaultMessage: 'Lähetä viesti'
    }
});

export { messages };

const TextInputWithAttachments = (props) => {
    
    const textareaLineHeight = 24;
    const textareaMaxRows = 6;
    
    const lng = props.intl.formatMessage;
    const [errorMessages, setErrorMessages] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [files, setFiles] = useState(props.files || []);
    const [loading, setLoading] = useState({});
    const [textareaRows, setTextareaRows] = useState(1);
    
    // Update attachments for parent component
    useEffect(() => {
        if (files && files.length > 0) {
            props.handleAttachments(files);
        }
        
    }, [files]);
    
    /**
     * Clear error messages
     */
    const clearErrors = () => {
        setErrorMessages([]);
        setUploadErrors([]);
    };
    
    /**
     * Add file to upload errors
     * @param fileName
     */
    const addUploadError = (fileName) => {
        // Add file name to upload errors
        setUploadErrors((prev) => [...prev, fileName]);
        // Remove file from the list
        setFiles(files.filter(file => file.name !== fileName));
    };
    
    /**
     * Validate new files
     * @param newFiles
     * @returns {*[]}
     */
    const validateNewFiles = (newFiles) => {
        
        let validFiles = [];
        
        let tooLargeFileNames = [];
        let duplicateFileNames = [];
        let errors = [];
        
        let currentFileNames = files.map((file) => file.name);
        const acceptedFileSizeInBytes = props.acceptedFileSize * props.byteConversionFactor;
        
        // Filter out files that exceed the size limit
        for (const file of newFiles) {

            if (currentFileNames.includes(file.name)) {
                // If file is already attached, add file name to duplicate list
                duplicateFileNames.push(file.name);
                
            } else if (file.size > acceptedFileSizeInBytes) {
                // If file exceeds the size limit, add file name to too large list
                tooLargeFileNames.push(file.name);
                
            } else {
                // If file is valid, add it to the valid files list
                validFiles.push(file);
                currentFileNames.push(file.name);
            }
        }
        
        // Generate error message if any files are duplicates
        if (duplicateFileNames.length > 0) {
            let error = lng(messages.duplicateFilesError) + ': ' + duplicateFileNames.join(', ') + '.';
            errors.push(error);
        }
        
        // Genereate error message if any files exceed the size limit
        if (tooLargeFileNames.length > 0) {
            let error = lng(messages.tooLargeFilesError1) + ' ';
            error += props.acceptedFileSize + props.sizeUnit + '. ';
            error += lng(messages.tooLargeFilesError2) + ': ' + tooLargeFileNames.join(', ') + '.';
            
            errors.push(error);
        }
        
        // Display error messages
        if (errors.length > 0) {
            setErrorMessages(errors);
        }
        
        return validFiles;
    };
    
    /**
     * Replace file in the list
     * @param newFile
     */
    const replaceFile = (newFile) => {
        setFiles((prevFiles) => {
            // Copy the list
            const updatedFiles = [...prevFiles];
            
            // Get file index for the file to be replaced
            const fileIndex = prevFiles.findIndex(file => file.name === newFile.name);
            
            // If file is found, replace it
            if (fileIndex !== -1) {
                updatedFiles[fileIndex] = newFile;
            }
            
            return updatedFiles;
        });
    };
    
    /**
     * Handle upload error
     * @param error
     * @param file
     */
    const handleUploadError = (error, file) => {
        // Authentication failed
        if (error.response?.status === 401) {
            // Log out from current session and redirect back to subscription login for authentication
            userLogout();
            // Show error message to user
            const alert = { message: lng(messages.authError), type: 'danger' };
            props.history.push({
                pathname: '/logout',
                alert
            });
        } else {
            // Request failed, add file name to upload errors
            addUploadError(file.name);
        }
    };
    
    /**
     * Handle file read
     * @param file
     * @param reader
     */
    const handleFileRead = (file, reader) => {
        
        // Set request headers and data
        const headers = userRequestHeaders();
        const fileData = JSON.stringify({
            filename: file.name,
            data: reader.result.split(',')[1] // Get Base64 data
        });
        
        // Send file data to the server to add the attachment
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + '/online/messagecenter/attachment',
            headers: headers,
            data: fileData
        })
        .then((response) => {
            // File uploaded successfully, add object ID to file
            file['objectid'] = response.data.object_id;
            replaceFile(file);
        })
        .catch((error) => handleUploadError(error, file))
        .finally(() => {
            // Hide loading animation
            setLoading((prev) => ({ ...prev, [file.name]: false }));
        });
    };
    
    /**
     * Upload file to the server
     * @param file
     */
    const uploadFile = (file) => {
        // Set loading spinner
        setLoading((prev) => ({ ...prev, [file.name]: true }));
        
        // Read file data
        const reader = new FileReader();
        reader.onload = () => handleFileRead(file, reader);
        // Handle file read error
        reader.onerror = () => addUploadError(file.name);
        // Read file as data URL
        reader.readAsDataURL(file);
    };
    
    /**
     * Handle file upload
     * @param event
     */
    const handleFileUpload = (event) => {
        clearErrors();
        
        // Get new files
        const newFiles = Array.from(event.target.files);
        
        // Check if the amount of files exceeds the file limit
        if (files.length + newFiles.length > props.acceptedFileAmount) {
            setErrorMessages([lng(messages.tooManyFilesError) + ' ' + props.acceptedFileAmount + '.']);
        } else {
            // Validate new files
            const validFiles = validateNewFiles(newFiles);
            
            // Add valid files to the list
            const updatedFiles = [...files, ...validFiles];
            setFiles(updatedFiles);
            
            // Upload files
            for (const file of validFiles) {
                uploadFile(file);
            }
        }
        
        // Reset file input value to allow choosing the same file again
        event.target.value = null;
    };
    
    /**
     * Handle file delete
     * @param fileName
     */
    const handleFileDelete = (fileName) => {
        clearErrors();
        setFiles(files.filter(file => file.name !== fileName));
    };
    
    /**
     * Handle textarea change
     * @param event
     */
    const handleTextareaChange = (event) => {
        const previousRows = event.target.rows;
        
        // Reset number of rows in textarea
        event.target.rows = 1;

        // Calculate current rows
        const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight) - 1;

        // Set row count to current rows, if it's not changed
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        // Set scroll, if textarea is full
        if (currentRows >= textareaMaxRows) {
            event.target.rows = textareaMaxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setTextareaRows(currentRows < textareaMaxRows ? currentRows : textareaMaxRows);
        props.handleInputChange(event);
    };

    // Method to clear attachments and input content
    const clearAttachmentsAndInput = () => {
        setFiles([]);
        setTextareaRows(1);
        document.querySelectorAll(`#${props.fieldId}`).forEach(input => {
            input.value = '';
        });
        props.updateCommentSubmitValue(false);
    };

    useEffect(() => {
        if (props.commentSubmitted) {
            clearAttachmentsAndInput();
        }
    }, [props.commentSubmitted]);
    
    return (
        <>
            {/* Display upload errors */}
            { uploadErrors && uploadErrors.length > 0 && (
                <AlertDialog
                    message={ lng(messages.uploadError) + ': ' + uploadErrors.join(', ') + '.' }
                    type='danger'
                />
            )}
            {/* Display other error messages */}
            { errorMessages && errorMessages.length > 0 && (
                <>
                    { errorMessages.map((errorMessage) => (
                        <AlertDialog
                            message={errorMessage}
                            key={errorMessage}
                            type='danger'
                        />
                    ))}
                </>
            )}

            {/* Display attachment list if attahcments are enabled and there are items in the list */}
            { props.attachmentsEnabled && files.length > 0 && (
                <>
                    <h6>{ lng(messages.attachments) }</h6>
                    {/* Attachment list */}
                    <Row className='grid' id='attachment-list'>
                        {/* Create attachment list items */}
                        { files.map((file) => (
                            <Col key={ file.name } xs={ 12 } md={ 6 } className="mt-1 mb-1">
                                <span className='me-2'>
                                    { loading[file.name] ? (
                                        // Loading spinner
                                        <Spinner animation="border" size="sm" />
                                    ) : (
                                        // Delete icon
                                        <FontAwesomeIcon
                                            icon={ far.faCircleXmark }
                                            className="icon icon-hover-danger clickable ms-2"
                                            onClick={ () => handleFileDelete(file.name) }
                                            aria-label={lng(messages.delete) + ' ' + file.name}
                                        />
                                    ) }
                                </span>
                                { file.name }
                            </Col>
                        ))}
                    </Row>
                </>
            )}
            
            <InputGroup className="mb-3">

                {/* Show attachment feature, if attachments are enabled */}
                { props.attachmentsEnabled && (
                        <>
                        {/* Attachment icon */}
                        <InputGroup.Text>
                            <label htmlFor="file-upload" className="m-0" aria-label={lng(messages.uploadFile)}>
                                <FontAwesomeIcon icon={far.faPaperclip} className='icon icon-primary clickable' />
                            </label>
                        </InputGroup.Text>
                        {/* Hidden attachment button */}
                        <Form.Control
                            type="file"
                            accept={props.acceptedFileTypes}
                            className="d-none"
                            multiple
                            onChange={handleFileUpload}
                            id="file-upload"
                        />
                    </>
                )}

                {/* Text field */}
                <Form.Control
                    as="textarea"
                    className='form-control'
                    rows={textareaRows}
                    id={props.fieldId}
                    placeholder={props.fieldName}
                    required={props.required}
                    onChange={handleTextareaChange}
                />
                <Form.Control.Feedback type="invalid" />
                
                {props.messageContent && (
                <Button id="message-center-modal-form-send" 
                        className="icon-button icon input-group-text" 
                        form={props.formId} 
                        variant="tertiary" 
                        type="submit" 
                        aria-label={lng(messages.sendMessage)}
                        disabled={props.isSubmitting}
                >    
                    {props.isSubmitting ? ( // Show spinner when submitting
                        <Spinner animation="border" size="sm" />
                    ) : (
                        <FontAwesomeIcon
                            icon={ fas.faPaperPlane }
                            className='icon icon-primary clickable'
                        />
                    )}
                </Button>
                )}
            </InputGroup>
            
        </>
    );
};

TextInputWithAttachments.propTypes = {
    'intl': PropTypes.object.isRequired,
    'fieldId': PropTypes.string.isRequired,
    'fieldName': PropTypes.string.isRequired,
    'required': PropTypes.bool.isRequired,
    'attachmentsEnabled': PropTypes.bool.isRequired,
    'acceptedFileAmount': PropTypes.number.isRequired,
    'acceptedFileTypes': PropTypes.string.isRequired,
    'acceptedFileSize': PropTypes.number.isRequired,
    'byteConversionFactor': PropTypes.number.isRequired,
    'sizeUnit': PropTypes.string.isRequired,
    'handleInputChange': PropTypes.func.isRequired,
    'handleAttachments': PropTypes.func.isRequired,
    'history': PropTypes.object,
    'commentSubmitted': PropTypes.bool,
    'updateCommentSubmitValue': PropTypes.func,
    'formId': PropTypes.string,
    'messageContent': PropTypes.bool,
    'files': PropTypes.array,
    'isSubmitting': PropTypes.bool
};

export default TextInputWithAttachments;