import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Tab, Form, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import { validate } from "../../../../services/helpers";
import AlertDialog from "../../../../components/AlertDialog";

// strings for translation in this view
const messages = defineMessages({
    invoiceSearchTitle: {
        id: "Hae laskun tiedoilla",
        defaultMessage: "Hae laskun tiedoilla"
    },
    dpidSearchTitle: {
        id: "Hae MyRopo-tunnuksella",
        defaultMessage: "Hae MyRopo-tunnuksella"
    },
    billnumLabel: {
        id: "Laskun numero",
        defaultMessage: "Laskun numero"
    },
    referenceLabel: {
        id: "Laskun viitenumero",
        defaultMessage: "Laskun viitenumero"
    },
    postcodeLabel: {
        id: "Laskulla oleva postinumerosi",
        defaultMessage: "Laskulla oleva postinumerosi"
    },
    jobIdLabel: {
        id: "Asianumero",
        defaultMessage: "Asianumero"
    },
    invoiceInformationLabel: {
        id: "Laskun tiedot",
        defaultMessage: "Laskun tiedot"
    },
    codeLabel: {
        id: "MyRopo-tunnus",
        defaultMessage: "MyRopo-tunnus"
    },
    searchLabel: {
        id: "Hae",
        defaultMessage: "Hae"
    },
    searchTitle: {
        id: "Hae lasku",
        defaultMessage: "Hae lasku"
    },
    searchDescription: {
        id: "Jos laskusi ei näy automaattisesti MyRopo-etusivulla, voi syynä olla laskun puuttuvat tunnistetiedot. Voit kuitenkin hakea laskua käyttämällä sen tietoja tai kirjeestämme löytyvää MyRopo-tunnusta.",
        defaultMessage: "Jos laskusi ei näy automaattisesti MyRopo-etusivulla, voi syynä olla laskun puuttuvat tunnistetiedot. Voit kuitenkin hakea laskua käyttämällä sen tietoja tai kirjeestämme löytyvää MyRopo-tunnusta."
    },
    tabSearchDescription: {
        id: "Hae laskua antamalla kaikki seuraavat tiedot.",
        defaultMessage: "Hae laskua antamalla kaikki seuraavat tiedot."
    },
    goBack: {
        id: 'Palaa takaisin',
        defaultMessage: 'Palaa takaisin'
    },
});

export { messages };


const RetrieveInvoiceModal = (props) => {

    const lng = props.intl.formatMessage;
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorType, setErrorType] = useState('light');
    const [activeTab, setActiveTab] = useState('invoice-search');
    const [retrieveInvoiceCriteria, setRetrieveInvoiceCriteria] = useState({
        jobid: 0,
        code: "",
        billnum: "",
        reference: "",
        postcode: "",
    });


    useEffect(() => {
        // set error to alertDialog if one is received
        if (props.show && props.error?.message !== '') {
            setErrorMessage(props.error.message);
            setErrorType(props.error.type);
            setShowError(true);
        }

    }, [props.error]);

    /**
     * Handle changes in input fields and remove errors. 
     * 
     * @param e 
     */
    function handleInputUpdate(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }

        // updates the values to state 
        setRetrieveInvoiceCriteria({
            ...retrieveInvoiceCriteria,
            [e.target.id]: e.target.value
        });
    }

    /**
     * Handle retrieving an invoice with invoice information or dpid.
     * Validate form data and create the proper query for retrieving the invoice.
    * 
    * @param e 
    */
    function handleSubmit(e) {
        e.preventDefault();

        if (validate(e.currentTarget)) {
            // clear alerts when data is valid
            clearAlerts();

            // form the query for api call
            let query = '';

            if (activeTab === 'invoice-search') {
                // form query from invoice information 
                query = 'dpid?querytype=invoice&reference=' + retrieveInvoiceCriteria.reference + '&billnum=' + retrieveInvoiceCriteria.billnum + '&postcode=' + retrieveInvoiceCriteria.postcode;

            } else if (activeTab === 'dpid-search') {
                // form query from job id and code 
                query = retrieveInvoiceCriteria.jobid + '-' + retrieveInvoiceCriteria.code;
            }

            props.handleSubmit(query);
        }
    }

    /**
     * Clear errors from alertDialog and hide it.
     */
    function clearAlerts() {
        setErrorMessage('');
        setShowError(false);
    }

    /**
     * Clear errors, set active tab back to default and close the modal.
     */
    function handleHide() {
        clearAlerts();
        setActiveTab('invoice-search');
        props.hide();
    }


    return (
        <Modal show={props.show} onHide={() => handleHide()}>
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={() => handleHide()}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> { lng(messages.goBack) }
                </Button>
                <Modal.Title>{ lng(messages.searchTitle) }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{ lng(messages.searchDescription) }</p>
                <Tab.Container id="retrieve-invoice-tabs" defaultActiveKey="invoice-search">
                    <Row className="pt-2">
                        <Col className="online-tabs retrieve-invoice">
                            <Nav
                                fill
                                justify
                                defaultActiveKey="invoice-search"
                                className="online-tabs "
                                onSelect={(e) => setActiveTab(e)}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="invoice-search" className="ps-0 pe-0" aria-label={lng(messages.invoiceSearchTitle)}>
                                        <div> {activeTab === 'invoice-search' ? <FontAwesomeIcon icon={far.faCircleDot} aria-hidden="true" /> : <FontAwesomeIcon icon={far.faCircle} aria-hidden="true" />} &nbsp;
                                            <span className='d-none d-sm-none d-md-inline '>{lng(messages.invoiceSearchTitle)}</span>
                                            <span className='d-md-none'>{ lng(messages.invoiceInformationLabel) }</span>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="dpid-search" className="ps-0 pe-0" aria-label={lng(messages.dpidSearchTitle)}>
                                        <div> {activeTab === 'dpid-search' ? <FontAwesomeIcon icon={far.faCircleDot} aria-hidden="true" /> : <FontAwesomeIcon icon={far.faCircle} aria-hidden="true" />} &nbsp;
                                            <span className='d-none d-sm-none d-md-inline'>{lng(messages.dpidSearchTitle)}</span>
                                            <span className='d-md-none '>{lng(messages.codeLabel)}</span>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane id="invoice-search" eventKey="invoice-search" className="show">
                                    <Row className="g-4 mt-0">
                                        <Col>
                                            <p>{ lng(messages.tabSearchDescription) }</p>
                                            {showError && (
                                                <AlertDialog
                                                    message={errorMessage}
                                                    type={errorType}
                                                />
                                            )}
                                            <Form id="jobsearch-invoiceinfo-form" noValidate aria-label={lng(messages.invoiceSearchTitle)} onSubmit={handleSubmit}>
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="billnum" type="text" placeholder={lng(messages.billnumLabel)} onChange={(e) => handleInputUpdate(e)} required />
                                                    <Form.Label htmlFor="billnum">{lng(messages.billnumLabel)}</Form.Label>
                                                    <Form.Control.Feedback type="invalid" />
                                                </Form.Floating>
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="reference" type="text" placeholder={lng(messages.referenceLabel)} onChange={(e) => handleInputUpdate(e)} required />
                                                    <Form.Label htmlFor="reference">{lng(messages.referenceLabel)}</Form.Label>
                                                    <Form.Control.Feedback type="invalid" />
                                                </Form.Floating>
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="postcode" type="text" placeholder={lng(messages.postcodeLabel)} onChange={(e) => handleInputUpdate(e)} required />
                                                    <Form.Label htmlFor="postcode">{lng(messages.postcodeLabel)}</Form.Label>
                                                    <Form.Control.Feedback type="invalid" />
                                                </Form.Floating>
                                                <Button form="jobsearch-invoiceinfo-form" variant="primary" type="submit" aria-label={lng(messages.searchLabel)}>
                                                    {lng(messages.searchLabel)}
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane id="dpid-search" eventKey="dpid-search">
                                    <Row className="g-4 mt-0">
                                        <Col>
                                            <p>{ lng(messages.tabSearchDescription) }</p>
                                            {showError && (
                                                <AlertDialog
                                                    message={errorMessage}
                                                    type={errorType}

                                                />
                                            )}
                                            <Form id="jobsearch-dpid-form" noValidate onSubmit={handleSubmit}>
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="jobid" type="text" placeholder={lng(messages.jobIdLabel)} onChange={(e) => handleInputUpdate(e)} required />
                                                    <Form.Label htmlFor="jobid">{lng(messages.jobIdLabel)}</Form.Label>
                                                    <Form.Control.Feedback type="invalid" />
                                                </Form.Floating>
                                                <Form.Floating className="mb-3">
                                                    <Form.Control id="code" type="text" placeholder={lng(messages.codeLabel)} onChange={(e) => handleInputUpdate(e)} required />
                                                    <Form.Label htmlFor="code">{lng(messages.codeLabel)}</Form.Label>
                                                    <Form.Control.Feedback type="invalid" />
                                                </Form.Floating>
                                                <Button form="jobsearch-dpid-form" variant="primary" type="submit">
                                                    {lng(messages.searchLabel)}
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    );
};

RetrieveInvoiceModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'error': PropTypes.object,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'handleSubmit': PropTypes.func,
};

export default RetrieveInvoiceModal;