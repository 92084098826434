import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from "react-intl";

const LiveChat = (props) => {

    useEffect(() => {
        loadLiveChatApi();
    });

    const chatLoaded = () => {
        if (window.LC_API) {
            setCallbacks();
            if (typeof props.onChatLoaded === 'function') {
                props.onChatLoaded(window.LC_API);
            }
        }
    };

    function chatNotLoaded() {
        if (typeof props.onErrorLoading === 'function') {
            props.onErrorLoading();
        }
    }

    function loadLiveChatApi() {
        if (!window.LC_API) {
            window.__lc = window.__lc || {};
            window.__lc.license = props.license;
            window.__lc.group = props.group;
            window.__lc.chat_between_groups = props.chatBetweenGroups;
            window.__lc.params = props.params;
            window.__lc.visitor = props.visitor;
            const lc = document.createElement('script');
            lc.type = 'text/javascript';
            lc.async = true;
            lc.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
            const s = document.getElementsByTagName('script')[0];

            if(typeof s !== "undefined") {
                s.parentNode.insertBefore(lc, s);
                lc.addEventListener('load', chatLoaded);
                lc.addEventListener('error', chatNotLoaded);
            }
        }
    }

    function setCallbacks() {
        if (typeof props.onBeforeLoad === 'function') {
            window.LC_API.on_before_load = props.onBeforeLoad;
        }

        if (typeof props.onAfterLoad === 'function') {
            window.LC_API.on_after_load = props.onAfterLoad;
        }

        if (typeof props.onChatWindowOpened === 'function') {
            window.LC_API.on_chat_window_opened = props.onChatWindowOpened;
        }

        if (typeof props.onChatWindowMinimized === 'function') {
            window.LC_API.on_chat_window_minimized = props.onChatWindowMinimized;
        }

        if (typeof props.onChatWindowHidden === 'function') {
            window.LC_API.on_chat_window_hidden = props.onChatWindowHidden;
        }

        if (typeof props.onChatStateChanged === 'function') {
            window.LC_API.on_chat_state_changed = props.onChatStateChanged;
        }

        if (typeof props.onChatStarted === 'function') {
            window.LC_API.on_chat_started = props.onChatStarted;
        }

        if (typeof props.onChatEnded === 'function') {
            window.LC_API.on_chat_ended = props.onChatEnded;
        }

        if (typeof props.onMessage === 'function') {
            window.LC_API.on_message = props.onMessage;
        }

        if (typeof props.onTicketCreated === 'function') {
            window.LC_API.on_ticket_created = props.onTicketCreated;
        }

        if (typeof props.onPrechatSurveySubmitted === 'function') {
            window.LC_API.on_prechat_survey_submitted = props.onPrechatSurveySubmitted;
        }

        if (typeof props.onRatingSubmitted === 'function') {
            window.LC_API.on_rating_submitted = props.onRatingSubmitted;
        }

        if (typeof props.onRatingCommentSubmitted === 'function') {
            window.LC_API.on_rating_comment_submitted = props.onRatingCommentSubmitted;
        }
    }
};

LiveChat.propTypes = {
    // important
    license: PropTypes.number.isRequired,
    group: PropTypes.number,
    onChatLoaded: PropTypes.func,
    // less important
    params: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired
    })),
    visitor: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string
    }),
    chatBetweenGroups: PropTypes.bool,
    onBeforeLoad: PropTypes.func,
    onAfterLoad: PropTypes.func,
    onChatWindowOpened: PropTypes.func,
    onChatWindowMinimized: PropTypes.func,
    onChatWindowHidden: PropTypes.func,
    onChatStateChanged: PropTypes.func,
    onChatStarted: PropTypes.func,
    onChatEnded: PropTypes.func,
    onMessage: PropTypes.func,
    onTicketCreated: PropTypes.func,
    onPrechatSurveySubmitted: PropTypes.func,
    onPostchatSurveySubmitted: PropTypes.func,
    onRatingSubmitted: PropTypes.func,
    onRatingCommentSubmitted: PropTypes.func,
};

LiveChat.defaultProps = {
    group: 0,
};

export default injectIntl(LiveChat);
